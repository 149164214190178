import React from 'react';
import experiments from '@/utils/labs/experiments';
import Script from 'next/script';

const setupScriptVariables = () =>
  `
    var pbjs = pbjs || {};
    pbjs.que = pbjs.que || [];
  `;

const notDangerousHtml = {
  __html: setupScriptVariables(),
};

type TPrebidScriptProps = {
  prebidUrl: string;
  labsConfig: TLabsConfig;
};

const PrebidScript = ({ prebidUrl, labsConfig }: TPrebidScriptProps) => {
  const { isPubmaticWrapperEnabled } = experiments({
    labsConfig,
  });

  if (isPubmaticWrapperEnabled) {
    return null;
  }

  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <Script strategy="lazyOnload" dangerouslySetInnerHTML={notDangerousHtml} />
      <Script strategy="lazyOnload" type="text/javascript" src={prebidUrl} />
    </>
  );
};

export default PrebidScript;
