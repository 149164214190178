import React from 'react';
import { string } from 'prop-types';
import experiments from '@/utils/labs/experiments';
import Script from 'next/script';

const setupScriptVariables = () =>
  `
    var googletag = googletag || {};
    googletag.cmd = googletag.cmd || [];
  `;

const notDangerousHtml = {
  __html: setupScriptVariables(),
};

type TGptScriptProps = {
  gptUrl: string;
  labsConfig: TLabsConfig;
  pwtPubId?: string;
  pwtProfId?: string;
};

const GptScript = ({ gptUrl, labsConfig, pwtPubId, pwtProfId }: TGptScriptProps) => {
  const { isPubmaticWrapperEnabled } = experiments({
    labsConfig,
  });

  if (isPubmaticWrapperEnabled) {
    const setupPubmaticScript = () =>
      `
        var PWT={}; //Initialize Namespace
        var googletag = googletag || {};
        googletag.cmd = googletag.cmd || [];
        var gptRan = false;
        var isPubmaticTimedOut = false;
        PWT.jsLoaded = function(){ //PubMatic pwt.js on load callback is used to load GPT
          loadGPT();
        };
        var loadGPT = function() {
          // Check the gptRan flag
          if (!gptRan) {
            gptRan = true;
            var gads = document.createElement('script');
            var useSSL = 'https:' == document.location.protocol;
            gads.src = (useSSL ? 'https:' : 'http:') + '${gptUrl}';
            var node = document.getElementsByTagName('script')[0];
            node.parentNode.insertBefore(gads, node);
          }
        };

        var loadGPTAfterTimeout = function() {
          // Check the gptRan flag
          if (!gptRan) {
            gptRan = true;
            var gads = document.createElement('script');
            var useSSL = 'https:' == document.location.protocol;
            gads.src = (useSSL ? 'https:' : 'http:') + '${gptUrl}';
            var node = document.getElementsByTagName('script')[0];
            node.parentNode.insertBefore(gads, node);

            isPubmaticTimedOut = true;
          }
        };
        // Failsafe to call gpt. ms timeout can be updated as per publisher preference.
        setTimeout(loadGPTAfterTimeout, 2000);
 
        (function() {
          var purl = window.location.href;
          var url = '//ads.pubmatic.com/AdServer/js/pwt/${pwtPubId}/${pwtProfId}';
          var profileVersionId = '';
          if(purl.indexOf('pwtv=')>0){
            var regexp = /pwtv=(.*?)(&|$)/g;
            var matches = regexp.exec(purl);
            if(matches.length >= 2 && matches[1].length > 0){
              profileVersionId = '/'+matches[1];
            }
          }
          var wtads = document.createElement('script');
          wtads.async = true;
          wtads.type = 'text/javascript';
          wtads.src = url+profileVersionId+'/pwt.js';
          var node = document.getElementsByTagName('script')[0];
          node.parentNode.insertBefore(wtads, node);
        })();
      `;

    return (
      <>
        <Script
          strategy="lazyOnload"
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: setupPubmaticScript() }}
        />
      </>
    );
  }

  return (
    <>
      <Script strategy="lazyOnload" dangerouslySetInnerHTML={notDangerousHtml} />
      <Script strategy="lazyOnload" type="text/javascript" src={gptUrl} async />
    </>
  );
};

GptScript.propTypes = {
  gptUrl: string.isRequired,
};

export default GptScript;
