import get from 'lodash.get';

type TExperimentsProps = {
  labsConfig: TLabsConfig;
};

export const labsABSwitchInState = (labsConfig: TLabsConfig, abSwitch: string, group: string) =>
  get(labsConfig, `abSwitches[${abSwitch}].group`) === group;

const experiments = ({ labsConfig }: TExperimentsProps) => {
  const getLabsConfig = ({ experiment, group }: { experiment: string; group: string }) =>
    labsABSwitchInState(labsConfig, experiment, group) || false;
  const getFeatureSwitch = ({ feature }: { feature: string }) =>
    get(labsConfig, 'featureSwitches') &&
    labsConfig.featureSwitches &&
    labsConfig.featureSwitches.indexOf(feature) > -1;

  return {
    skinTest: getFeatureSwitch({ feature: 'skinTest' }),
    reblazeSwitchEnabled: getFeatureSwitch({ feature: 'BNL142-reblaze' }),
    isHoustonEnabled: getFeatureSwitch({ feature: 'houston' }),
    enableTmp:
      getLabsConfig({ experiment: 'AUR5875', group: 'B' }) || getLabsConfig({ experiment: 'AUR5875', group: 'C' }),
    isECGJsBannerCDN: getLabsConfig({ experiment: 'ECGJsBannerCDN', group: 'B' }),
    isShippingCampaignEnabled: labsABSwitchInState(labsConfig, 'BNL21106', 'B'),
    isDAC7CommunicationEnabled: labsABSwitchInState(labsConfig, 'BNL26495', 'B'),
    isFetchResultsOnceEnabled: labsABSwitchInState(labsConfig, 'BNL23670', 'B'),
    isFetchResultsOnceAllCatEnabled: labsABSwitchInState(labsConfig, 'BNL23670v2', 'B'),
    isDataDrivenContentEnabled: labsABSwitchInState(labsConfig, 'BNL21242', 'B'),
    isSellerDataDrivenContentEnabled: labsABSwitchInState(labsConfig, 'BNL24316', 'B'),
    isSmartBannerExperimentEnabled: labsABSwitchInState(labsConfig, 'BNL25733', 'B'),
    isPlexusTitleExperimentEnabled: labsABSwitchInState(labsConfig, 'BNL24563', 'B'),
    isCarsExtendedAdDetailsEnabled: labsABSwitchInState(labsConfig, 'BNL27987', 'B'),
    isPaaEnabled: labsABSwitchInState(labsConfig, 'BNL26068', 'B'),
    isPubmaticWrapperEnabled: labsABSwitchInState(labsConfig, 'BNL28223', 'B'),
    isIntowowEnabledNLGroupA: labsABSwitchInState(labsConfig, 'BNL28785', 'A'),
    isIntowowEnabledNLGroupB: labsABSwitchInState(labsConfig, 'BNL28785', 'B'),
    isIntowowEnabledTWHGroupA: labsABSwitchInState(labsConfig, 'BNL29367', 'A'),
    isIntowowEnabledTWHGroupB: labsABSwitchInState(labsConfig, 'BNL29367', 'B'),
  };
};

// Experiment keys to track through Datadog RUM to understand effects on core web vitals
export const experimentsToTrackDatadogRUM = ['BNL21242', 'BNL27987'];

export default experiments;
